import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import type { AwsCredentialIdentityProvider } from "@smithy/types";
import { filetypeextension, filetypemime } from "magic-bytes.js";
import type { UploadedFile } from "../../../schema/UploadedFile.js";
import { slugify } from "../../slugify.js";

export class StorageClient {
	private readonly s3Client: S3Client;

	constructor(
		region: string,
		private readonly bucket: string,
		credentials: AwsCredentialIdentityProvider,
	) {
		this.s3Client = new S3Client({ region, credentials });
	}

	public async uploadFile(folder: string, file: Blob | File): Promise<UploadedFile> {
		const fileName = file instanceof File ? file.name : "file";
		const fileNameSplitByDot = fileName.split(".");
		const name = fileNameSplitByDot.slice(0, -1).join(".") || "file";
		const extension = fileName !== "file" ? fileNameSplitByDot.pop() : undefined;
		const arrayBuffer = await file.arrayBuffer();
		const bytes = new Uint8Array(arrayBuffer);

		const detectedFileMimeType = filetypemime(bytes).pop();
		const detectedFileExtension = filetypeextension(bytes)
			.filter((ext) => ext !== "apng")
			.pop();

		const fileMime = (file.type || detectedFileMimeType) ?? "unknown";
		const fileExtension = (extension ?? detectedFileExtension ?? "").toLowerCase();

		const slugifiedName = slugify(name);
		const command = new PutObjectCommand({
			Key: `${folder}/${slugifiedName}-${crypto.randomUUID()}.${fileExtension}`,
			Body: bytes,
			Bucket: this.bucket,
			ContentType: fileMime,
		});
		await this.s3Client.send(command);

		return {
			type: fileMime,
			path: command.input.Key ?? "",
			size: file.size,
			title: name,
		};
	}
}
