import { InMemoryCache } from "@apollo/client/cache/inmemory/inMemoryCache.js";
import type { MutationOptions, NormalizedCacheObject, OperationVariables } from "@apollo/client/core";
import { ApolloClient } from "@apollo/client/core/ApolloClient.js";
import type { QueryOptions } from "@apollo/client/core/watchQueryOptions.js";
import { ApolloLink } from "@apollo/client/link/core/ApolloLink.js";
import { createHttpLink } from "@apollo/client/link/http/createHttpLink.js";
import type { AwsCredentialIdentityProvider } from "@smithy/types";
import { createAuthLink } from "aws-appsync-auth-link";
import { type FieldNode, Kind, type OperationDefinitionNode } from "graphql/language/index.js";
import type { Empty } from "../../Empty.js";
import { deeplyConvertNullToUndefined } from "../../deeplyConvertNullToUndefined.js";
import { deeplyConvertUndefinedToNull } from "../../deeplyConvertUndefinedToNull.js";
import { retryFailedToFetch } from "../../retryFailedToFetch.js";
import { AppSyncError } from "./AppSyncError.js";

export class GraphQLClient {
	private readonly apolloClient: ApolloClient<NormalizedCacheObject>;

	constructor(
		region: string,
		graphqlEndpoint: string,
		credentials: AwsCredentialIdentityProvider,
		connectToDevTools: boolean,
	) {
		const auth = createAuthLink({
			url: graphqlEndpoint,
			region,
			auth: {
				type: "AWS_IAM",
				credentials,
			},
		});
		const http = createHttpLink({ uri: graphqlEndpoint, fetch, includeUnusedVariables: true });
		this.apolloClient = new ApolloClient({
			link: ApolloLink.from([auth, http]),
			cache: new InMemoryCache({
				addTypename: false,
			}),
			defaultOptions: {
				query: { fetchPolicy: "no-cache", errorPolicy: "all" },
				mutate: { fetchPolicy: "no-cache", errorPolicy: "all" },
			},
			connectToDevTools,
		});
	}

	private getMutationName<Variables, Response>(operation: MutationOptions<Response, Variables>): string {
		const node = operation.mutation.definitions
			.find((definition): definition is OperationDefinitionNode => definition.kind === Kind.OPERATION_DEFINITION)
			?.selectionSet.selections.find((selection): selection is FieldNode => "name" in selection);
		return node?.name.value ?? "";
	}

	private getQueryName<Variables, Response>(operation: QueryOptions<Variables, Response>): string {
		const node = operation.query.definitions
			.find((definition): definition is OperationDefinitionNode => definition.kind === Kind.OPERATION_DEFINITION)
			?.selectionSet.selections.find((selection): selection is FieldNode => "name" in selection);
		return node?.name.value ?? "";
	}

	public async query<Response, Variables extends OperationVariables = Empty>(
		options: QueryOptions<Variables, Record<string, Response>>,
	): Promise<Response | undefined> {
		const { data, errors } = await retryFailedToFetch(() =>
			this.apolloClient.query({
				...options,
				variables: deeplyConvertUndefinedToNull(options.variables),
			}),
		);
		if (errors) {
			throw new AppSyncError(errors);
		}
		return deeplyConvertNullToUndefined(data[this.getQueryName(options)]) as Response;
	}

	public async mutate<Variables extends OperationVariables, Response = undefined>(
		options: MutationOptions<Record<string, Response>, Variables>,
	): Promise<Response> {
		const { data, errors } = await this.apolloClient.mutate({
			...options,
			variables: deeplyConvertUndefinedToNull(options.variables),
		});
		if (errors) {
			throw new AppSyncError(errors);
		}
		return deeplyConvertNullToUndefined(data?.[this.getMutationName(options)] ?? undefined) as Response;
	}
}
