import { environmentKeys } from "./environmentKeys";

export const environmentVariables = {} as Record<(typeof environmentKeys)[number], string>;

if (import.meta.env.DEV) {
	for (const key of environmentKeys) {
		const value = String(import.meta.env[key]);
		if (value === "undefined") {
			throw new Error(`Environment variable ${key} is undefined`);
		}
		environmentVariables[key] = value;
	}
} else if (typeof window !== "undefined") {
	const configScript = window.document.getElementById("config");
	if (!configScript) {
		throw new Error("Missing config element");
	}
	const configJson = configScript.textContent?.trim() ?? "";
	const config = JSON.parse(configJson) as Record<string, string>;

	for (const key of environmentKeys) {
		const value = config[key];
		if (value === undefined) {
			throw new Error(`Missing environment variable ${key}`);
		}
		environmentVariables[key] = value;
	}
}
