export function makeInflect(singular: string, plural: string, rest: string) {
	return function (count: number): string {
		if (count === 1) {
			return singular;
		} else if (count >= 2 && count <= 4) {
			return plural;
		} else {
			return rest;
		}
	};
}
