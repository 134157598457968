export const environmentKeys = [
	"AWS_IDENTITY_POOL_ID",
	"AWS_REGION",
	"AWS_USER_POOL_CLIENT_ID",
	"AWS_USER_POOL_ID",
	"DOMAIN",
	"ENV",
	"ENV_DOMAIN",
	"LOGISTIKAVPRAXI_ADMIN",
	"PRIVATE_GRAPHQL_ENDPOINT",
	"STORAGE_BUCKET",
	"STORAGE_URL",
	"SYNC_AUTH",
	"UPLOAD_BUCKET",
	"UPLOAD_URL",
	"VERSION",
] as const;
