export class FailedToFetchAfterRetries extends Error {
	constructor(public readonly retryAmount: number) {
		super(`Failed to fetch after ${retryAmount} tries.`);
		this.name = this.constructor.name;
	}
}

// eslint-disable-next-line @typescript-eslint/ban-types
export async function retryFailedToFetch<Result>(callback: () => Promise<Result>, maxTries = 3): Promise<Result> {
	let tries = 0;
	const seconds = 2.5;

	do {
		// eslint-disable-next-line @typescript-eslint/no-loop-func,no-loop-func
		await new Promise((resolve) => {
			setTimeout(resolve, tries * seconds * 1000);
		});

		try {
			return await callback();
		} catch (error) {
			if (!(error instanceof Error) || (error.message !== "Failed to fetch" && error.message !== "Load failed")) {
				throw error;
			}
		}

		tries++;
	} while (tries < maxTries);

	throw new FailedToFetchAfterRetries(tries);
}
