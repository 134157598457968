import type { GraphQLError } from "graphql/error/GraphQLError.js";

export interface AppSyncGraphQLError extends GraphQLError {
	readonly errorType?: string;
	readonly errorInfo?: unknown;
	readonly data?: unknown;
}

export class AppSyncError extends Error {
	constructor(public readonly errors: readonly AppSyncGraphQLError[]) {
		super(errors.map((error) => error.message).join(", "));
	}
}
