import { environmentVariables } from "$lib/config/environmentVariables";
import { GraphQLClient } from "@core/utils/aws/appsync/GraphQLClient";
import { CognitoClient } from "@core/utils/aws/cognito/CognitoClient";
import { StorageClient } from "@core/utils/aws/s3/StorageClient";

export let cognitoClient: CognitoClient;
export let graphQLClient: GraphQLClient;
export let storageClient: StorageClient;
export let uploadStorageClient: StorageClient;

export function createClients(): void {
	const {
		AWS_REGION,
		AWS_USER_POOL_ID,
		AWS_IDENTITY_POOL_ID,
		AWS_USER_POOL_CLIENT_ID,
		PRIVATE_GRAPHQL_ENDPOINT,
		STORAGE_BUCKET,
		UPLOAD_BUCKET,
	} = environmentVariables;

	cognitoClient = new CognitoClient(
		AWS_REGION,
		AWS_USER_POOL_CLIENT_ID,
		AWS_IDENTITY_POOL_ID,
		AWS_USER_POOL_ID,
		false,
		(credentialProvider) => {
			graphQLClient = new GraphQLClient(AWS_REGION, PRIVATE_GRAPHQL_ENDPOINT, credentialProvider, import.meta.env.DEV);
			storageClient = new StorageClient(AWS_REGION, STORAGE_BUCKET, credentialProvider);
			uploadStorageClient = new StorageClient(AWS_REGION, UPLOAD_BUCKET, credentialProvider);
		},
	);
}
