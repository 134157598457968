/* eslint-disable no-template-curly-in-string */
import { setLocale } from "yup";
import { makeInflect } from "@core/utils/makeInflect";

const inflectElement = makeInflect("prvek", "prvky", "prvků");
const inflectCharacter = makeInflect("znak", "znaky", "znaků");

export function setupCzechMessagesForYup(): void {
	setLocale({
		mixed: {
			default: "Neplatná hodnota",
			required: "Pole je povinné",
			notType: "Pole musí být typu ${type}",
			defined: "Pole musí být definované",
			notNull: "Pole nesmí být prázdné",
			oneOf: "Pole musí být jedna z následujících hodnot: ${values}",
			notOneOf: "Pole nesmí být jedna z následujících hodnot: ${values}",
		},
		string: {
			email: "E-mailová adresa není platná",
			max: ({ max }) => `Pole nesmí obsahovat více než ${max} ${inflectCharacter(max)}`,
			min: ({ min }) => `Pole musí obsahovat alespoň ${min} ${inflectCharacter(min)}`,
			url: "Nejedná se o platný odkaz",
			trim: "Pole nesmí obsahovat mezery na začátku nebo na konci",
			length: ({ length }) => `Pole musí obsahovat přesně ${length} ${inflectCharacter(length)}`,
			matches: "Pole nesplňuje požadovaný formát",
			uuid: "Pole musí obsahovat platné UUID",
			lowercase: "Pole musí obsahovat pouze malá písmena",
			uppercase: "Pole musí obsahovat pouze velká písmena",
		},
		boolean: {
			isValue: "Pole musí být typu boolean",
		},
		number: {
			max: "Pole musí být menší nebo rovno ${max}",
			min: "Pole musí být větší nebo rovno ${min}",
			lessThan: "Pole musí být menší než ${less}",
			moreThan: "Pole musí být větší než ${more}",
			negative: "Pole musí být záporné",
			positive: "Pole musí být kladné",
			integer: "Pole musí být celé číslo",
		},
		object: {
			noUnknown: "Pole nesmí obsahovat neznámé klíče",
		},
		date: {
			max: "Datum musí být před ${max}",
			min: "Datum musí být po ${min}",
		},
		tuple: {
			notType: "Pole musí být typu pole",
		},
		array: {
			max: ({ max }) => `Pole nesmí obsahovat více než ${max} ${inflectElement(max)}`,
			min: ({ min }) => `Pole musí obsahovat alespoň ${min} ${inflectElement(min)}`,
			length: ({ length }) => `Pole musí obsahovat přesně ${length} ${inflectElement(length)}`,
		},
	});
}
