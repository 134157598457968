export function deeplyConvertUndefinedToNull<T>(object: T): T {
	const result: Record<string, unknown> = {};

	if (Array.isArray(object)) {
		return object.map(deeplyConvertUndefinedToNull) as T;
	}

	if (object !== null && object !== undefined && typeof object === "object") {
		for (const [key, value] of Object.entries(object)) {
			result[key] = deeplyConvertUndefinedToNull(value as Record<string, unknown>);
		}

		return result as T;
	}

	return (object ?? null) as T;
}
