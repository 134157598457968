import { createClients } from "$lib/api/createClients";
import { setupCzechMessagesForYup } from "$lib/yup/setupCzechMessagesForYup";
import { enableCache } from "@iconify/svelte";
import { consoleSandbox } from "@sentry/utils";
import type { HandleClientError } from "@sveltejs/kit";

setupCzechMessagesForYup();
createClients();
enableCache("session");

export const handleError = (input: Parameters<HandleClientError>[0]): ReturnType<HandleClientError> => {
	consoleSandbox(() => {
		// eslint-disable-next-line no-console
		console.log(input.error);
	});

	if (input.error instanceof Error) {
		return {
			message: input.error.message,
		};
	}
};
